import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import DoctorSubCategory from "./SubCategory/DoctorSubCategory";

function ChemistSubCategory() {

    const docdata = useLocation();
  const queryParams = new URLSearchParams(docdata.search);
  // const userid = queryParams.get("id");
  const fullname = queryParams.get("fullname");

  const [item, setItem] = useState([]);
  useEffect(() => {
    fetch("http://localhost/doctor_admin/New/Api/doctoregister.php")
      .then(res => res.json())
      .then(
        (result) => {
          setItem(result);
        }
      )
  }, [])

  return (
    <div className="Section_full">
      {/* <div className="sidebar">
        <div className="Text_div">Dashboard
          <input className="table_username" value={fullname} disabled="true" ></input>
          <hr style={{ border: '2px solid #F15943', width: '50%' }} />

        </div>

        <a className="active" href="#home">New Appointments</a>
        <a href="#news">Accepted Appointments</a>
        <a href="#contact">Feedback</a>

        <button className="btn btn-warning">Quick Book Appointment</button>

      </div> */}
      <div className="">
      <h2 style={{color: "#f15943",padding:"90px 0 0 0 "}}>Sub-Categories</h2>
                <Link to="/CategoriesList"> <h2>Chimest</h2></Link>
                <hr style={{ border: '2px solid #F15943', width: '100%' }} />
         <div className="section_row">
             <div className="section_column" >
               <img src="./images/subcategory/Lab-Test.png" alt="" width="80px" height="80px" />  <h4>Lab Test</h4>
                
             </div>
             <div className="section_column" >
              <img src="./images/subcategory/Medical-Stores.png" alt="" width="80px" height="80px" /> <h4>Medical Stores</h4>
               
             </div>
             

             </div>

      </div>
      {/* <div className=""><img src="./images/categogies/Book-Appointment--banner.jpg" alt="" width="100%" height="600px" /> </div> */}
    </div>
  );



}
export default ChemistSubCategory;