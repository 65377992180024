import React, { useState,useEffect } from "react"
import "./css/style1.css"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Quickbooking() {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setSelectedSubcategory(''); // Reset the subcategory when changing the category
    };

    const handleSubcategoryChange = (e) => {
        setSelectedSubcategory(e.target.value);
    };

    const [selectedDate, setSelectedDate] = useState(null);
    const [isValidAge, setIsValidAge] = useState(true);

    const handleDateChange = (date) => {
        setSelectedDate(date);

        // Validate the age
        const today = new Date();
        const minAgeDate = new Date();
        minAgeDate.setFullYear(today.getFullYear() - 18);

        setIsValidAge(date <= minAgeDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValidAge) {
            // Perform registration or further actions
            console.log('Registration successful!');
        } else {
            console.log('Age must be 18 or above to register!');
        }
    };
    const [Users, setUsers] = useState([]);

  const fetchUserData = () => {
    fetch("http://localhost/doctor_admin/New/Api/Fetch_Role.php")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }

  useEffect(() => {
    fetchUserData()
  }, [])
    return (
        <>
        <div className="quick_section">
           
            <form className="modal-content quick" method="POST" action="http://localhost/doctor_admin/New/Api/appointment.php">
                 
                <div className="container">
                <h2 className="heading_center">Quick Booking Form</h2>
                    <hr />
                    <label htmlFor="text"><b>User Name</b></label>
                    <input type="text" placeholder="Enter User Name" name="name" required />

                    <label htmlFor="email"><b>Email Address</b></label>
                    <input type="text" placeholder="Enter Email Address" name="email" required />



                    <label htmlFor="dob">
                        <b> Date of Birth:</b> </label><br/>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            value={handleSubmit}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select Date"
                            name="dob"
                        />
                   

                    {!isValidAge && (
                        <p style={{ color: '#fff' }}>Age must be 18 or above to register!</p>
                    )}<br />

                    <label htmlFor="text"><b>Mobile Number</b></label>
                    <input type="text" placeholder="Enter Mobile Number" name="mobile_number" required />




                    <label htmlFor="text"><b>Pincode</b></label>
                    <input type="text" placeholder="Enter Your Pincode" name="pincode" required />

                    <label htmlFor="text"><b>State</b></label>
                    <input type="text" placeholder="Enter Your State" name="state" required />

                    <label htmlFor="text"><b>Role</b></label><br/>
                    {/* <input type="text" placeholder="Enter Your Role" name="role" required /> */}
                    {/* <select name="role" id="service">
                            <option >Select</option>
                            <option name="role">Nurse</option>
                            <option name="role">Doctor</option>
                            <option name="role">Chemist</option>
                            <option name="role">Curch</option>
                        </select> */}
                        <label htmlFor="text"><b>Choose a Service</b></label>
                        <select name="role" value={selectedCategory} onChange={handleCategoryChange}>
                            <option name="role">Choose a Service:</option>
                           
                                {Users.map(User => (
                                    <option value={User.role_name} key={User.id}>{User.role_name}</option>
                                    ))}</select> 
                    
                    {selectedCategory && (
                        <select name="sub_category" value={selectedSubcategory} onChange={handleSubcategoryChange}>
                            <option value="">Select Subcategory</option>
                            {selectedCategory === 'Doctor' && (
                                <>
                                    <option value="Allergists / Immunologists">Allergists / Immunologists</option>
                                    <option value="Anesthesiologists">Anesthesiologists</option>
                                    <option value="Cardiologists">Cardiologists</option>
                                    <option value="Colon and Rectal Surgeons">Colon and Rectal Surgeons</option>
                                    <option value="Critical Care Medicine Specialists">Critical Care Medicine Specialists</option>
                                    <option value="Dermatologists">Dermatologists</option>
                                    <option value="Endocrinologists">Endocrinologists</option>
                                    <option value="Gastroenterologists">Gastroenterologists</option>
                                    <option value="Geriatric Medicine Specialists">Geriatric Medicine Specialists</option>
                                    <option value="Hematologists">Hematologists</option>
                                    <option value="Hospice & Palliative Medicine Specialists">Hospice & Palliative Medicine Specialists</option>
                                    <option value="Infectious Disease Specialists">Infectious Disease Specialists</option>
                                    <option value="Internists">Internists</option>
                                    <option value="Medical Geneticists">Medical Geneticists</option>
                                    <option value="Nephrologists">Nephrologists</option>
                                    <option value="Neurologists">Neurologists</option>
                                    <option value="Obstetricians and Gynecologists">Obstetricians and Gynecologists</option>
                                    <option value="Oncologists">Oncologists</option>
                                    <option value="Osteopaths">Osteopaths</option>
                                    <option value="Otolaryngologists">Otolaryngologists</option>
                                    <option value="Pathologists">Pathologists</option>
                                    <option value="Pediatricians">Pediatricians</option>
                                    <option value="Physiatrists">Physiatrists</option>
                                    <option value="Plastic Surgeons">Plastic Surgeons</option>
                                    <option value="Podiatrists">Podiatrists</option>
                                    <option value="Preventive Medicine Specialists">Preventive Medicine Specialists</option>
                                    <option value="Psychiatrists">Psychiatrists</option>
                                    <option value="Pulmonologists">Pulmonologists</option>
                                    <option value="Radiologists">Radiologists</option>
                                    <option value="Rheumatologists">Rheumatologists</option>
                                    <option value="Sleep Medicine Specialists">Sleep Medicine Specialists</option>
                                    <option value="Sports Medicine Specialists">Sports Medicine Specialists</option>
                                    <option value="General Surgeons">General Surgeons</option>
                                    <option value="Urologists">Urologists</option>

                                    {/* Add more subcategory options for Category 1 */}
                                </>
                            )}
                            {selectedCategory === 'Nurse' && (
                                <>
                                    <option value="Old Age Home Nurses">Old Age Home Nurses</option>
                                    <option value="Home Nurses">Home Nurses</option>
                                    {/* Add more subcategory options for Category 2 */}
                                </>
                            )}
                            {selectedCategory === 'Chemist' && (
                                <>
                                    <option value="Lab Test">Lab Test</option>
                                    <option value="Medical Stores">Medical Stores</option>
                                    {/* Add more subcategory options for Category 2 */}
                                </>
                            )}
                            {selectedCategory === 'Curch' && (
                                <>
                                    <option value="Private Daycare Chains">Private Daycare Chains</option>
                                    <option value="subcPrivate or Stand - Alone Nurseriesategory4">Private or Stand - Alone Nurseries</option>
                                    <option value="Home Based Creches">Home Based Creches</option>
                                    <option value="In Home Daycare">In Home Daycare</option>
                                    <option value="Nanny">Nanny</option>
                                    <option value="Shared Nanny">Shared Nanny</option>
                                    <option value="Au Pair">Au Pair</option>
                                    <option value="Babysitter">Babysitter</option>
                                    <option value="Traditional Daycare Center">Traditional Daycare Center</option>
                                    <option value="Relative Care">Relative Care</option>
                                    <option value="Preschool">Preschool</option>
                                    <option value="Workplace Creches">Workplace Creches</option>
                                    <option value="Daycare Centres To Independent Schools">Daycare Centres To Independent Schools</option>

                                    
                                </>
                            )}
                         
                        </select>
                    )}
                    
                    {/* <label>
                            <input type="checkbox" name="remember" /> Remember me
                        </label>

                        <p>By creating an account you agree to our Terms & Privacy.</p> */}

                    <div className="clearfix">

                        <button type="submit" name="submit" className="signupbtn buton1">Book</button>
                    </div>
                </div>
            </form>
            </div>
             </>
    );
}
export default Quickbooking;