import React from "react";

function RoleInsert() {
    return (
        <>
      <div className="signformD_section">
        

            <form id="signformD" className="modal-content animate" action="http://localhost/doctor_admin/New/Api/Role_Insert.php" method="POST" enctype="multipart/form-data">
                <div className="container">
                <h2 className="heading_center">Insert A Any Role</h2>
                    <div className="imgcontainer">

                        <img src="/images/doctor.png" alt="Avatar" className="avatar" />
                    </div>
                    
                    <label htmlFor="file"><b>Image</b></label>
                    <input type="file" placeholder="Select a Image" name="filename" id="filename" required /><br/><br/>

                    <label htmlFor="role_name"><b>Role</b></label>
                    <input type="text" placeholder="Enter Role Name" name="role_name" required />

                    


                   
                    <button className="buton1" name="submit" type="submit">Insert</button>


                    {/* <div className="container" >
                        <button type="button" className="cancelbtn">Cancel</button>
                        <span className="psw">Forgot <a href="#">password?</a></span>
                    </div> */}
                </div>
            </form>
            </div></>
    );
}
export default RoleInsert;