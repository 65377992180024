import React, { useEffect, useState } from "react"

function SubRoleInsert() {
  const [Users, setUsers] = useState([]);

  const fetchUserData = () => {
    fetch("http://localhost/doctor_admin/New/Api/Fetch_Role.php")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }

  useEffect(() => {
    fetchUserData()
  }, [])
  return (
    <>
      <div className="signformD_section">


        <form id="signformD" className="modal-content animate" action="http://localhost/doctor_admin/New/Api/SubRole_Insert.php" method="POST" encType="multipart/form-data">
          <div className="container">
            <h2 className="heading_center">Insert A Any SubRole</h2>
            <div className="imgcontainer">

              <img src="/images/doctor.png" alt="Avatar" className="avatar" />
            </div>

            <label htmlFor="file"><b>Image</b></label>
            <input type="file" placeholder="Select a Image" name="filename" id="filename" required /><br /><br />
           
            <select name="role_name">
              <option >Choose a Role:</option>
              {Users.map(User => (
                <option value={User.role} key={User.role_id}>{User.role_name}</option>
              ))}</select>


            <label htmlFor="sub_cat_name"><b>SubRole</b></label>
            <input type="text" placeholder="Enter Sub Role Name" name="sub_cat_name" required />


            <button className="buton1" name="submit" type="submit">Insert</button>


            {/* <div className="container" >
                        <button type="button" className="cancelbtn">Cancel</button>
                        <span className="psw">Forgot <a href="#">password?</a></span>
                    </div> */}
          </div>
        </form>
      </div></>
  );
}
export default SubRoleInsert;