import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import "./css/style1.css"
import SignupDoctor from './SignupDoctor';
import SignupPatients from './SignupPatients';

function Signup() {
    // const navigate = useNavigate();
    // const signupPPage = useCallback(()=>{
    //     navigate("/signuppatients")
    //   },[navigate]);
    //   const signupDPage = useCallback(()=>{
    //     navigate("/signupdoctor")
    //   },[navigate]);
    const [isHidden, setIsHidden] = useState(false);

    const handleHideClick = () => {
        setIsHidden(true);
    };

    const handleShowClick = () => {
        setIsHidden(false);
    };

    return (
        <>
            <div className="signup_form">
                <div className="container">
                    <div className='section_divided'>
                        {/* <h1>Sign In</h1>
            <p>Please fill in this form to create an account.</p>
            <hr /> */}
                        {/* <div className="S_button"><a href onClick={myFunction}>Signin For Patient</a></div>
            <div id="form" className="S_button"><a href onClick={myFunction2}>Signin For Doctor</a></div> */}

                        <div className="button_parent">
                            <h2 className="heading_center">Register Choose Account Type</h2>
                            <div className="button_login"><img src="/images/patient.png" alt="Avatar" className="avatar" /><button id="show" className="sigin_box" onClick={handleShowClick}>Patient</button>
                            </div>
                            <div className="button_login"><img src="/images/doctor.png" alt="Avatar" className="avatar" /><button id="hide" className="sigin_box" onClick={handleHideClick}>Doctor</button>   </div>
                            <div className="login_para"><p>Hello User,<br />
                                Please Fill Out The Form Below To Get Start.</p></div>
                        </div>


                    </div>
                  
                    <p style={{ display: isHidden ? 'block' : 'none' }}> <SignupDoctor /></p>
                    <div style={{ display: isHidden ? 'none' : 'block' }}> <SignupPatients />  </div>
                </div>
            </div>


        </>
    );
}
export default Signup;