import React, { useEffect, useState } from 'react'
import Card from './Card';


const Display = () => {
  
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fileredCard, setFilteredCard] = useState([]);

  const addCategory = (category) => {
    if(!selectedCategories.includes(category)){
        setSelectedCategories(prev => ([...prev, category]))
    }     
  }

  const removeCategory = (category) => {
    if(selectedCategories.includes(category)){
        console.log(selectedCategories)
        const removedList = selectedCategories.filter((item) => (item !== category));
        setSelectedCategories(removedList);
    }
  }

  const resetCategory = () => {
    setSelectedCategories([]);
  }

  useEffect(() => {
    if(selectedCategories.length === 0){
        setFilteredCard(card);
    } else{
        setFilteredCard(card.filter((item)=>(selectedCategories.includes(item.category))));
    }
  }, [selectedCategories, card])

  const getCategories = async () => {
    setLoading(true);

    await fetch('http://localhost/doctor_admin/New/Api/specialistcategory.php')
    .then(res => res.json())
    .then(data => {
        setCategories(data);
    })
    .catch(err => alert(err))
    .finally(()=>{
        setLoading(false);
    })
  }

  const getCards = async () => {
    setLoading(true);
    
    await fetch('http://localhost/doctor_admin/New/Api/specialistfetch.php')
    .then(res => res.json())
    .then(data => {
        setCard(data.detail);
        setFilteredCard(data.detail);
        getCategories(); // get the categories list
    })
    .catch(err => alert(err))
    .finally(()=>{
        setLoading(false);
    })
  }

  useEffect(() => {
    getCards();
  }, [])
  
  return (
    <div className='w-screen h-screen px-5 bg-gray-100 flex justify-center items-center'>
        <div className='w-full h-[90%] rounded-md bg-white'> 
            <div className='relative w-full h-[15%] flex items-center overflow-x-auto'>
                <span className='mx-3 ml-5 font-medium'> Categories: </span>
                {
                    categories.map((category) => (
                        <div
                            onClick={() => {
                                if(selectedCategories.includes(category)){
                                    removeCategory(category);
                                } else{
                                    addCategory(category);
                                }
                            }} 
                            className={`w-fit min-w-fit h-8 mx-2 px-5 py-2 flex flex-row justify-center items-center text-sm border break-keep rounded-3xl cursor-pointer transition-all duration-300 ${(selectedCategories.includes(category))?'border-blue-500 bg-blue-500 text-white':' border-gray-500 bg-white text-gray-900'} `}>
                            {categories.split("-").join(" ")}
                        </div>
                    ))
                }
                <div
                    onClick={() => resetCategory()} 
                    className={`${(selectedCategories.length>0)?'opacity-100':'opacity-0 pointer-events-none'} sticky right-0 w-fit h-full px-5 flex justify-center items-center text-blue-500 bg-white backdrop-blur-lg cursor-pointer hover:text-blue-700 transition-all duration-300`}
                >
                    clear
                </div>
            </div>
            <Card filteredcard={fileredCard} loading={loading} />
        </div>
    </div>
  )
}

export default Display