import React from 'react';

import "./css/style1.css"
// import SignFormdoctor from './SignFormdoctor';
// import SignFormpatient from './SignupPatients';

function SignForpatient() {

//     const [isHidden, setIsHidden] = useState(false);

//     const handleHideClick = () => {
//       setIsHidden(true);
//     };
  
//     const handleShowClick = () => {
//       setIsHidden(false);
//     };
    return (
        <>
            <div className="signformP_section">



                <form id="signformP" className="modal-content animate" action="http://localhost/doctor_admin/New/Api/signinpatients.php" method="POST">
                    <div className="container">
                        <h2 className="heading_center">Welcome To Patient's Account Login</h2>
                        <div className="imgcontainer">

                            <img src="/images/patient.png" alt="Avatar" className="avatar" />
                        </div>


                        <label htmlFor="uname"><b>Username</b></label>
                        <input type="text" placeholder="Enter Username" name="email" required />

                        <label htmlFor="psw"><b>Password</b></label>
                        <input type="password" placeholder="Enter Password" name="password" required />


                        {/* <button id="show" className="buton1" onClick={handleShowClick}>Patient</button>
            <button id="hide" className="buton1" onClick={handleHideClick}>Doctor</button>
            <p style={{ display: isHidden ? 'block' : 'none' }}> <SignFormdoctor /></p>
          <div style={{ display: isHidden ? 'none' : 'block' }}> <SignFormpatient />  </div>
                        
                        <div>
                            <input style={{ display: isHidden ? 'block' : 'none' }} type="radio" id="patient" name="select_category" value="patient" />Patient<SignFormdoctor /></div>
                       
                    
                        <input type="radio" id="doctor" name="select_category" value="doctor" />Doctor */}
                      
                        <br />
                        <button className="buton1" name="submit" type="submit">Login</button>


                        {/* <div className="container" >
                        <button type="button" className="cancelbtn">Cancel</button>
                        <span className="psw">Forgot <a href="#">password?</a></span>
                    </div> */}
                    </div>
                </form>







                {/* <div className="second_section">
                    <h2 className="heading_center">What is Lorem Ipsum?</h2>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>

                </div> */}



            </div>
        </>
    );
}
export default SignForpatient;