import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";


function RoleSubCategory() {
   
    const docdata = useLocation();
    const queryParams = new URLSearchParams(docdata.search);
    // const userid = queryParams.get("id");
    const fullname = queryParams.get("fullname");

    const [, setItem] = useState([]);
    useEffect(() => {
        fetch("http://localhost/doctor_admin/New/Api/doctoregister.php")
            .then(res => res.json())
            .then(
                (result) => {
                    setItem(result);
                }
            )
    }, [])

    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };
    
    const [Users, setUsers] = useState([]);

    const fetchUserData = () => {
        fetch("http://localhost/doctor_admin/New/Api/Fetch_Sub.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setUsers(data)
            })
    }

    useEffect(() => {
        fetchUserData()
    }, [])

   
    return (
        <>
            <div className="Section_full">
                <div className="sidebar">
                    <div className="Text_div">Dashboard
                        <input className="table_username" value={fullname} disabled="true" ></input>
                        <hr style={{ border: '2px solid #F15943', width: '50%' }} />

                    </div>

                    <Link to="/bookedappointment" className="active" >New Appointments</Link>
                    <a href="#news">Scheduled Appointments</a>
                    <a href="#contact">Feedback</a>


                    <button onClick={handleButtonClick} className="btn btn-warning">Quick Book Appointment</button>
                    {isOpen && (
                        <div>
                            {/* Dropdown content */}

                            <Link to="/Quickbooking"> <div>For Self</div></Link>
                            <Link to="/Quickbooking">For Family Members</Link>
                            <Link to="/Quickbooking">For other</Link>

                        </div>
                    )}

                </div>
                <div className="main_div">
                    <div className="buttons"></div>
                    <div className="section_row">
                        <div className="section_column" >

                            {Users.map(User => (

                                <div key={User.sub_cat_name} className="role_get" >
                                   
                                 Category:   {User.role_name}<br/>
 <img src={`http://localhost/doctor_admin/New/Api/image/${User.filename}`} width={'200px'} height={'200px'} alt="SubRoles"/><br/>
                                   Name: {User.sub_cat_name}<br/>
                                ID: {User.role_id} 

                                
{/* <img src={`http://localhost/doctor_admin/New/Api/image/${User.filename}`} width={'200px'} height={'200px'} alt="SubRoles"/>
                                  <h4>{User.sub_cat_name}</h4> 
                                  {User.id} */}
                                </div>
                            ))}


                        
                        </div>
                      

                    </div> </div>
            </div>
        </>
    );



}
export default RoleSubCategory;