import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';



function ButtonLogin() {
    return (
        <>
            <div class="full-height">
                <center>
                    <table border="0">
                        <tr>

                            <td width="10%">
                                <a href="login.php" class="non-style-link"><p className="nav-item">LOGIN</p></a>
                            </td>
                            <td width="10%">
                                <a href="signup.php" class="non-style-link"><p className="nav-item" >REGISTER</p></a>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <p className="heading-text">Avoid Hassles & Delays.</p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <p className="sub-text2">How is health today, Sounds like not good!<br />Don't worry. Find your doctor online Book as you wish with eDoc. <br/>
                                    We offer you a free doctor channeling service, Make your appointment now.</p>
                            </td>
                        </tr>
                        <tr>

                            <td colspan="3">
                                <center>
                                    <a href="login.php" >
                                        <input type="button" value="Make Appointment" className="login-btn btn-primary btn" />
                                    </a>
                                </center>
                            </td>

                        </tr>

                    </table>

                </center>

            </div>
        </>
    );
}

export default ButtonLogin;