import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import DoctorSubCategory from "./DoctorSubCategory";
// import NurseSubCategory from "./NurseSubCategory";
// import ChemistSubCategory from "./ChemistSubCategory";
// import CrechSubCategory from "./CrechSubCategory";
// import TestPage from "../TestPage";
// import ChemistSubCategory from "./ChemistSubCategory";
// import CrechSubCategory from "./CrechSubCategory";
// import DoctorSubCategory from "./SubCategory/DoctorSubCategory";

function CategoriesList() {

  const docdata = useLocation();
  const queryParams = new URLSearchParams(docdata.search);
  // const userid = queryParams.get("id");
  const fullname = queryParams.get("fullname");

  const [, setItem] = useState([]);
  useEffect(() => {
    fetch("http://localhost/doctor_admin/New/Api/doctoregister.php")
      .then(res => res.json())
      .then(
        (result) => {
          setItem(result);
        }
      )
  }, [])



  // const [visibleDivId, setVisibleDivId] = useState(null);

  // function divVisibility(divId) {
  //   if (visibleDivId === divId) {
  //     setVisibleDivId(null);
  //   } else {
  //     setVisibleDivId(divId);
  //   }
  // }

  // function hideNonVisibleDivs() {
  //   const divs = ["Div1", "Div2", "Div3", "Div4"];
  //   divs.forEach((divId) => {
  //     const div = document.getElementById(divId);
  //     if (visibleDivId === divId) {
  //       div.style.display = "block";
  //     } else {
  //       div.style.display = "none";
  //     }
  //   });
  // }

  // // Call hideNonVisibleDivs after updating the state of visibleDivId
  // React.useEffect(() => {
  //   hideNonVisibleDivs();
  // }, [visibleDivId]);


  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };
  const [Users, setUsers] = useState([]);

  const fetchUserData = () => {
    fetch("http://localhost/doctor_admin/New/Api/Fetch_Role.php")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }

  useEffect(() => {
    fetchUserData()
  }, [])
  

  return (
    <>
      <div className="Section_full">
        <div className="sidebar">
          <div className="Text_div">Dashboard
            <input className="table_username" value={fullname} disabled="true" ></input>
            <hr style={{ border: '2px solid #F15943', width: '50%' }} />

          </div>

          <Link to="/bookedappointment" className="active" >New Appointments</Link>
          <a href="#news">Scheduled Appointments</a>
          <a href="#contact">Feedback</a>


          <button onClick={handleButtonClick} className="btn btn-warning">Quick Book Appointment</button>
          {isOpen && (
            <div>
              {/* Dropdown content */}

              <Link to="/Quickbooking"> <div>For Self</div></Link>
              <Link to="/Quickbooking">For Family Members</Link>
              <Link to="/Quickbooking">For other</Link>

            </div>
          )}

        </div>
        <div className="main_div">
          <div className="buttons"></div>
          <div className="section_row">
            <div className="section_column" >

            
              {Users.map(User => (

                <div key={User.role_name} className="role_get" >
 id:  {User.id} <br/>
              <Link to={"/rolesubcategory/"}>    <img src={`http://localhost/doctor_admin/New/Api/image/${User.filename}`} width={'100px'} height={'100px'} alt="Roles"/><br/>
           
                  <h4>Name: {User.role_name}</h4></Link>
                </div>
              ))}
              </div>

          </div> </div>
        {/* <div className="content_bar">
          <h2>Categories</h2>
          <div className="main_div">
            <div className="buttons"></div>
            <div className="section_row">
              <Link to="" onClick={() => divVisibility("Div1")}><div className="section_column" >
                <img src="./images/categogies/doctor.png" alt="" width="80px" height="80px" />  <h4>Doctor</h4>

              </div></Link>
              <Link to="" onClick={() => divVisibility("Div2")}><div className="section_column" >
                <img src="./images/categogies/Nurse.png" alt="" width="80px" height="80px" /> <h4>Nurses</h4>

              </div></Link>
              <Link to="" onClick={() => divVisibility("Div3")}> <div className="section_column" >
                <img src="./images/categogies/chemist.png" alt="" width="80px" height="80px" /> <h4>Chemist</h4>
              </div></Link>
              <Link to="" onClick={() => divVisibility("Div4")}>  <div className="section_column" >
                <img src="./images/categogies/Crech.png" alt="" width="80px" height="80px" /> <h4>Crech</h4>

              </div></Link>
              <div className="main_div">

                <div className="inner_div">
                  <div id="Div1"><DoctorSubCategory /></div>
                  <div id="Div2" style={{ display: "none" }}>
                    <NurseSubCategory />
                  </div>
                  <div id="Div3" style={{ display: "none" }}>
                    <ChemistSubCategory />
                  </div>
                  <div id="Div4" style={{ display: "none" }}>
                    <CrechSubCategory />
                  </div>
                </div>
              </div>



            </div>

          </div>
        </div> */}


        {/* <div className=""><img src="./images/categogies/Book-Appointment--banner.jpg" alt="" width="100%" height="600px" /> </div> */}
      </div>
    </>
  );



}
export default CategoriesList;