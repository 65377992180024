import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";


function DoctorSubCategory() {

  // const docdata = useLocation();
  // const queryParams = new URLSearchParams(docdata.search);
  // // const userid = queryParams.get("id");
  // const fullname = queryParams.get("fullname");

  const [item, setItem] = useState([]);
  useEffect(() => {
    fetch("http://localhost/doctor_admin/New/Api/doctoregister.php")
      .then(res => res.json())
      .then(
        (result) => {
          setItem(result);
        }
      )
  }, [])

  return (
    <div>
      {/* <div className="sidebar">
        <div className="Text_div">Dashboard
          <input className="table_username" value={fullname} disabled="true" ></input>
          <hr style={{ border: '2px solid #F15943', width: '50%' }} />

        </div>

        <a className="active" href="#home">New Appointments</a>
        <a href="#news">Accepted Appointments</a>
        <a href="#contact">Feedback</a>

      </div> */}

      <div className="subcat_space">


        <h2 style={{color: "#f15943",padding:"300px 0px 0px 0px "}}>Sub-Categories</h2>
        <h2>Doctor</h2>
        <hr style={{ border: '2px solid #f15943', width: '100%' }} />
        <div className="section_row">
          <div className="section_column" >
            <img src="./images/subcategory/Allergists.png" alt="Allergists" width="80px" height="80px" />  <h4>Allergists / Immunologists</h4>

          </div>
          <div className="section_column" >
            <img src="./images/subcategory/Anesthesiologists.png" alt="Anesthesiologists" width="80px" height="80px" /> <h4>Anesthesiologists</h4>

          </div>
          <div className="section_column">
            <img src="./images/subcategory/Cardiologists.png" alt="" width="80px" height="80px" />  <h4>Cardiologists</h4>

          </div>


          <div className="section_column" >
            <img src="./images/subcategory/Colon-and-Rectal-Surgeons.png" alt="" width="80px" height="80px" />   <h4>Colon and Rectal Surgeons</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Critical-Care-Medicine-Specialists.png" alt="" width="80px" height="80px" />   <h4>Critical Care Medicine Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Dermatologists.png" alt="" width="80px" height="80px" />   <h4>Dermatologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Endocrinologists.png" alt="" width="80px" height="80px" />   <h4>Endocrinologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Gastroenterologists.png" alt="" width="80px" height="80px" />   <h4>Gastroenterologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Geriatric-Medicine-Specialists.png" alt="" width="80px" height="80px" />   <h4>Geriatric Medicine Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Hematologists.png" alt="" width="80px" height="80px" />   <h4>Hematologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Hospice-&-Palliative.png" alt="" width="80px" height="80px" />   <h4>Hospice & Palliative Medicine Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Infectious-Disease-Specialists.png" alt="" width="80px" height="80px" />   <h4>Infectious Disease Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Internists.png" alt="" width="80px" height="80px" />   <h4>Internists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Medical-Geneticists.png" alt="" width="80px" height="80px" />   <h4>Medical Geneticists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Nephrologists.png" alt="" width="80px" height="80px" />   <h4>Nephrologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Neurologists.png" alt="" width="80px" height="80px" />   <h4>Neurologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Obstetricians-and-Gynecologists.png" alt="" width="80px" height="80px" />   <h4>Obstetricians and Gynecologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Oncologists.png" alt="" width="80px" height="80px" />   <h4>Oncologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Ophthalmologists.png" alt="" width="80px" height="80px" />   <h4>Ophthalmologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Osteopaths.png" alt="" width="80px" height="80px" />   <h4>Osteopaths</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Otolaryngologists.png" alt="" width="80px" height="80px" />   <h4>Otolaryngologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Pathologists.png" alt="" width="80px" height="80px" />   <h4>Pathologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Pediatricians.png" alt="" width="80px" height="80px" />   <h4>Pediatricians</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Physiatrists.png" alt="" width="80px" height="80px" />   <h4>Physiatrists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Plastic-Surgeons.png" alt="" width="80px" height="80px" />   <h4>Plastic Surgeons</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Podiatrists.png" alt="" width="80px" height="80px" />   <h4>Podiatrists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Preventive-Medicine-Specialists.png" alt="" width="80px" height="80px" />   <h4>Preventive Medicine Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Psychiatrists.png" alt="" width="80px" height="80px" />   <h4>Psychiatrists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Pulmonologists.png" alt="" width="80px" height="80px" />   <h4>Pulmonologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Radiologists.png" alt="" width="80px" height="80px" />   <h4>Radiologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Rheumatologists.png" alt="" width="80px" height="80px" />   <h4>Rheumatologists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Sleep-Medicine-Specialists.png" alt="" width="80px" height="80px" />   <h4>Sleep Medicine Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Sports-Medicine-Specialists.png" alt="" width="80px" height="80px" />   <h4>Sports Medicine Specialists</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/General-Surgeons.png" alt="" width="80px" height="80px" />   <h4>General Surgeons</h4>
          </div>

          <div className="section_column" >
            <img src="./images/subcategory/Urologists.png" alt="" width="80px" height="80px" />   <h4>Urologists</h4>
          </div>




        </div>

      </div>
      {/* <div className=""><img src="./images/categogies/Book-Appointment--banner.jpg" alt="" width="100%" height="600px" /> </div> */}
    </div>
  );



}
export default DoctorSubCategory;