import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import DoctorSubCategory from "./SubCategory/DoctorSubCategory";

function CrechSubCategory() {

    const docdata = useLocation();
    const queryParams = new URLSearchParams(docdata.search);
    // const userid = queryParams.get("id");
    const fullname = queryParams.get("fullname");

    const [item, setItem] = useState([]);
    useEffect(() => {
        fetch("http://localhost/doctor_admin/New/Api/doctoregister.php")
            .then(res => res.json())
            .then(
                (result) => {
                    setItem(result);
                }
            )
    }, [])

    return (
        <div className="Section_full">
            {/* <div className="sidebar">
                <div className="Text_div">Dashboard
                    <input className="table_username" value={fullname} disabled="true" ></input>
                    <hr style={{ border: '2px solid #F15943', width: '50%' }} />

                </div>

                <a className="active" href="#home">New Appointments</a>
                <a href="#news">Accepted Appointments</a>
                <a href="#contact">Feedback</a>

                <button className="btn btn-warning">Quick Book Appointment</button>

            </div> */}
            <div className="">
            <h2 style={{color: "#f15943",padding:"90px 0px 0px 0px "}}>Sub-Categories</h2>
                <Link to="/CategoriesList"> <h2>Crech</h2></Link>
                <hr style={{ border: '2px solid #F15943', width: '100%' }} />
                <div className="section_row">
                    <div className="section_column" >
                        <img src="./images/subcategory/Private-Daycare--Chains.png" alt="" width="80px" height="80px" />  <h4>Private Daycare
                            Chains</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Private-or-StandAlone-Nurseries.png" alt="" width="80px" height="80px" /> <h4>Private or Stand-
Alone Nurseries</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Home-Based-Creches.png" alt="" width="80px" height="80px" /> <h4>Home Based
Creches</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/In-Home-Daycare.png" alt="" width="80px" height="80px" /> <h4>In Home Daycare</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Nanny.png" alt="" width="80px" height="80px" /> <h4>Nanny</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Shared-Nanny.png" alt="" width="80px" height="80px" /> <h4>Shared Nanny</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Au-Pair.png" alt="" width="80px" height="80px" /> <h4>Au Pair</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Babysitter.png" alt="" width="80px" height="80px" /> <h4>Babysitter</h4>

                    </div>

                     <div className="section_column" >
                         <img src="./images/subcategory/Traditional-Daycare-Center.png" alt="" width="80px" height="80px" /> <h4>Traditional Daycare Center</h4>

                    </div>
                     <div className="section_column" >
                         <img src="./images/subcategory/Relative-Care.png" alt="" width="80px" height="80px" /> <h4>Relative Care</h4>

                    </div>
                     <div className="section_column" >
                         <img src="./images/subcategory/Preschool.png" alt="" width="80px" height="80px" /> <h4>Preschool</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Workplace-Creches.png" alt="" width="80px" height="80px" /> <h4>Workplace Creches</h4>

                    </div>
                    <div className="section_column" >
                         <img src="./images/subcategory/Daycare-Centres.png" alt="" width="80px" height="80px" /> <h4>Daycare Centres To Independent Schools</h4>

                    </div>
                    


                </div>

            </div>
            {/* <div className=""><img src="./images/categogies/Book-Appointment--banner.jpg" alt="" width="100%" height="600px" /> </div> */}
        </div>
    );



}
export default CrechSubCategory;