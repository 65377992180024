import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';



function Home() {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2
    };
    var settings_client = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        speed: 5000,
    };

    //   const navigate = useNavigate();
    // const confirmationPage = useCallback(()=>{
    //     navigate("/confirmation")
    //   },[navigate]);
    const [Items, setItem] = useState([]);

    const fetchUserData = () => {
        fetch("http://localhost/doctor_admin/New/Api/specialistfetch.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setItem(data)
            })
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const [Users, setUser] = useState([]);

    const fetchUserData1 = () => {
        fetch("http://localhost/doctor_admin/New/Api/specialistcategory.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setUser(data)
            })
    }

    useEffect(() => {
        fetchUserData1()
    }, [])
    return (
        <div>


            <div className="banner">

                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-12 col-xl-7">
                            <div className="block">
                                <div className="divider mb-3"></div>
                                <span className="text-uppercase text-sm letter-spacing ">Total Health care solution</span>
                                <h1 className="mb-3 mt-3">Your most trusted health partner</h1>

                                <p className="mb-4 pr-5">Choose Medexpertz, your reliable healthcare partners, for comprehensive healthcare solutions. With just a single click, you can get all healthcare services.</p>
                                <div className="btn-container ">
                                    <Link to="/appointment" className="btn btn-main-2 btn-icon btn-round-full">Make appointment <i className="icofont-simple-right ml-2  "></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-block d-lg-flex">
                                <div className="feature-item mb-5 mb-lg-0">
                                    <div className="feature-icon mb-4">
                                        <i className="icofont-surgeon-alt"></i>
                                    </div>
                                    <span>24 Hours Service</span>
                                    <h4 className="mb-3">Online Appointment</h4>
                                    <p className="mb-4">Get ALl time support for emergency.We have introduced the principle of family medicine.</p>
                                    <Link to="/appointment" className="btn btn-main btn-round-full">Make a appointment</Link>
                                </div>

                                <div className="feature-item mb-5 mb-lg-0">
                                    <div className="feature-icon mb-4">
                                        <i className="icofont-ui-clock"></i>
                                    </div>
                                    <span>Timing schedule</span>
                                    <h4 className="mb-3">Working Hours</h4>
                                    <ul className="w-hours list-unstyled">
                                        <li className="d-flex justify-content-between">Sun - Wed : <span>8:00 - 17:00</span></li>
                                        <li className="d-flex justify-content-between">Thu - Fri : <span>9:00 - 17:00</span></li>
                                        <li className="d-flex justify-content-between">Sat - sun : <span>10:00 - 17:00</span></li>
                                    </ul>
                                </div>

                                <div className="feature-item mb-5 mb-lg-0">
                                    <div className="feature-icon mb-4">
                                        <i className="icofont-support"></i>
                                    </div>
                                    <span>Emegency Cases</span>
                                    <h4 className="mb-3">+1 (732) 123-6900</h4>
                                    <p>Get ALl time support for emergency.We have introduced the principle of family medicine.Get Conneted with us for any urgency .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section service-2 cust_services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="service-block block1 mb-5">
                                <img src="images/service/service-1.jpg" alt="" className="img-fluid" />
                                <div className="content">
                                    <h4 className="mt-4 mb-2 title-color">Quick Video Consultation</h4>
                                    <p className="mb-4">Connect in Just a Few Seconds.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="service-block block1 mb-5">
                                <img src="images/service/service-2.jpg" alt="" className="img-fluid" />
                                <div className="content">
                                    <h4 className="mt-4 mb-2  title-color">Physicians in Your Area</h4>
                                    <p className="mb-4">Book Doctor Appointments</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="service-block block1 mb-5">
                                <img src="images/service/service-3.jpg" alt="" className="img-fluid" />
                                <div className="content">
                                    <h4 className="mt-4 mb-2 title-color">Meds</h4>
                                    <p className="mb-4">Necessities delivered right to your door.</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="service-block block1 mb-5 mb-lg-0">
                                <img src="images/service/service-4.jpg" alt="" className="img-fluid" />
                                <div className="content">
                                    <h4 className="mt-4 mb-2 title-color">Lab Testing</h4>
                                    <p className="mb-4">We pick up the sample at home.</p>
                                </div>
                            </div>
                        </div>

                    
                        {/* <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="service-block block1 mb-5 mb-lg-0">
                                <img src="images/service/service-6.jpg" alt="" className="img-fluid" />
                                <div className="content">
                                    <h4 className="mt-4 mb-2 title-color">Surgery</h4>
                                    <p className="mb-4">Reliable and secure surgical facilities.</p>
                                </div>
                            </div>
                        </div> */}

                        
                    </div>
                </div>
            </div>


            <div className="section about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="about-img">
                                <img src="images/about/img-1.jpg" alt="" className="img-fluid" />
                                <img src="images/about/img-2.jpg" alt="" className="img-fluid mt-4" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="about-img mt-4 mt-lg-0">
                                <img src="images/about/img-3.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="about-content pl-4 mt-4 mt-lg-0">
                                <h2 className="title-color">Personal care <br />& healthy living</h2>
                                <p className="mt-4 mb-5">We provide best leading medicle service Nulla perferendis veniam deleniti ipsum officia dolores repellat laudantium obcaecati neque.</p>

                                <Link to="/services" className="btn btn-main-2 btn-round-full btn-icon">Services<i className="icofont-simple-right ml-3"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-div ">
                <div className="container">
                    <div className="cta position-relative">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter-stat">
                                    <i className="icofont-doctor"></i>
                                    <span className="h3 counter" data-count="58">0</span>k
                                    <p>Happy People</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter-stat">
                                    <i className="icofont-flag"></i>
                                    <span className="h3 counter" data-count="700">0</span>+
                                    <p>Surgery Comepleted</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter-stat">
                                    <i className="icofont-badge"></i>
                                    <span className="h3 counter" data-count="40">0</span>+
                                    <p>Expert Doctors</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter-stat">
                                    <i className="icofont-globe"></i>
                                    <span className="h3 counter" data-count="20">0</span>
                                    <p>Worldwide Branch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="div service gray-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 text-center">
                            <div className="div-title">
                                <h2>Schedule a consultation appointment for in-clinic services</h2>
                                <div className="divider mx-auto my-4"></div>
                                <p>Get expert medical professionals in various fields</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-laboratory text-lg"></i>
                                    <h4 className="mt-3 mb-3">Dentist</h4>
                                </div>

                                <div className="content">
                                    <p className="mb-4">Problems with teething? Arrange a dental examination and get digital prescriptions</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-heart-beat-alt text-lg"></i>
                                    <h4 className="mt-3 mb-3">Gynecologist/obstetrician</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">look into therapies for infertility, pregnancy, and women's health</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-tooth text-lg"></i>
                                    <h4 className="mt-3 mb-3">Nutritionists/Dietitians</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">Get advice on healthy eating, managing weight, and sports dietary intake</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-crutch text-lg"></i>
                                    <h4 className="mt-3 mb-3">Physician</h4>
                                </div>

                                <div className="content">
                                    <p className="mb-4">Dragged a muscle? See a find qualified physiotherapist doctors near you for treatment</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-brain-alt text-lg"></i>
                                    <h4 className="mt-3 mb-3">General Surgeon</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">Do You Need Surgery? Select the appropriate surgeon</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-dna-alt-1 text-lg"></i>
                                    <h4 className="mt-3 mb-3">Orthopedist</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">Treat problems with bones and joints, injuries to the spine, and more</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-dna-alt-1 text-lg"></i>
                                    <h4 className="mt-3 mb-3">General Therapist</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">Locate the best family physician near you</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-dna-alt-1 text-lg"></i>
                                    <h4 className="mt-3 mb-3">Pediatricians</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">Contact child care specialists and infant physicians</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-item mb-4">
                                <div className="icon d-flex align-items-center">
                                    <i className="icofont-dna-alt-1 text-lg"></i>
                                    <h4 className="mt-3 mb-3">Gastroenterologist</h4>
                                </div>
                                <div className="content">
                                    <p className="mb-4">look for problems with the pancreas, liver, and digestive organs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section appoinment">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 ">
                            <div className="appoinment-content">
                                <img src="images/about/img-3.jpg" alt="" className="img-fluid" />
                                <div className="emergency">
                                    <h2 className="text-lg"><i className="icofont-phone-circle text-lg"></i>+1 (732) 123-6900</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10 ">
                            <div className="appoinment-wrap mt-5 mt-lg-0">
                                <h2 className="mb-2 title-color">Book appointment</h2>
                                <p className="mb-4">Mollitia dicta commodi est recusandae iste, natus eum asperiores corrupti qui velit . Iste dolorum atque similique praesentium soluta.</p>
                                <form id="#" className="appoinment-form" method="post" action="#">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option>Choose Department</option>
                                                    {Users.map(User => (
                                                        <option key={User.spl_id}>
                                                            <p className="mb-0">{User.spl_category}</p>
                                                        </option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select className="form-control" id="exampleFormControlSelect2">
                                                    <option>Select Doctors</option>
                                                    {Items.map(Item => (

                                                        <option key={Item.doc_id}>
                                                            <h4 className="mb-0">{Item.doc_name}</h4>
                                                            
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="date" id="date" type="date" className="form-control" placeholder="dd/mm/yyyy" required/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="time" id="time" type="time" className="form-control" placeholder="Time" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="name" id="name" type="text" className="form-control" placeholder="Full Name" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="phone" id="phone" type="tel" className="form-control" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group-2 mb-4">
                                        <textarea name="message" id="message" className="form-control" rows="6" placeholder="Your Message" required></textarea>
                                    </div>

                                    <Link to="#" href className="btn btn-main btn-round-full" >Make Appointment <i className="icofont-simple-right ml-2  "></i></Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section testimonial-2 gray-bg">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-title text-center">
                                <h2>We served over 5000+ Patients</h2>
                                <div className="divider mx-auto my-4"></div>
                                <p>Lets know moreel necessitatibus dolor asperiores illum possimus sint voluptates incidunt molestias nostrum laudantium. Maiores porro cumque quaerat.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-12 testimonial-wrap-2">
                            <Slider {...settings}>
                                <div>
                                    <div className="testimonial-block style-2  gray-bg">
                                        <i className="icofont-quote-right"></i>

                                        <div className="testimonial-thumb">
                                            <img src="images/team/test-thumb1.jpg" alt="" className="img-fluid" />
                                        </div>

                                        <div className="client-info ">
                                            <h4>Amazing service!</h4>
                                            <span>John Partho</span>
                                            <p>
                                                They provide great service facilty consectetur adipisicing elit. Itaque rem, praesentium, iure, ipsum magnam deleniti a vel eos adipisci suscipit fugit placeat.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="testimonial-block style-2  gray-bg">
                                        <div className="testimonial-thumb">
                                            <img src="images/team/test-thumb2.jpg" alt="" className="img-fluid" />
                                        </div>

                                        <div className="client-info">
                                            <h4>Expert doctors!</h4>
                                            <span>Mullar Sarth</span>
                                            <p>
                                                They provide great service facilty consectetur adipisicing elit. Itaque rem, praesentium, iure, ipsum magnam deleniti a vel eos adipisci suscipit fugit placeat.
                                            </p>
                                        </div>

                                        <i className="icofont-quote-right"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="testimonial-block style-2  gray-bg">
                                        <div className="testimonial-thumb">
                                            <img src="images/team/test-thumb3.jpg" alt="" className="img-fluid" />
                                        </div>

                                        <div className="client-info">
                                            <h4>Good Support!</h4>
                                            <span>Kolis Mullar</span>
                                            <p>
                                                They provide great service facilty consectetur adipisicing elit. Itaque rem, praesentium, iure, ipsum magnam deleniti a vel eos adipisci suscipit fugit placeat.
                                            </p>
                                        </div>

                                        <i className="icofont-quote-right"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="testimonial-block style-2  gray-bg">
                                        <div className="testimonial-thumb">
                                            <img src="images/team/test-thumb4.jpg" alt="" className="img-fluid" />
                                        </div>

                                        <div className="client-info">
                                            <h4>Nice Environment!</h4>
                                            <span>Partho Sarothi</span>
                                            <p className="mt-4">
                                                They provide great service facilty consectetur adipisicing elit. Itaque rem, praesentium, iure, ipsum magnam deleniti a vel eos adipisci suscipit fugit placeat.
                                            </p>
                                        </div>
                                        <i className="icofont-quote-right"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="testimonial-block style-2  gray-bg">
                                        <div className="testimonial-thumb">
                                            <img src="images/team/test-thumb1.jpg" alt="" className="img-fluid" />
                                        </div>

                                        <div className="client-info">
                                            <h4>Modern Service!</h4>
                                            <span>Kolis Mullar</span>
                                            <p>
                                                They provide great service facilty consectetur adipisicing elit. Itaque rem, praesentium, iure, ipsum magnam deleniti a vel eos adipisci suscipit fugit placeat.
                                            </p>
                                        </div>
                                        <i className="icofont-quote-right"></i>
                                    </div>
                                </div>

                            </Slider>

                        </div>
                    </div>
                </div>

            </div>


            <div className="section clients">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="div-title text-center">
                                <h2>Partners who support us</h2>
                                <div className="divider mx-auto my-4"></div>
                                <p>Lets know moreel necessitatibus dolor asperiores illum possimus sint voluptates incidunt molestias nostrum laudantium. Maiores porro cumque quaerat.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className=" clients-logo">
                        <Slider {...settings_client}>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/1.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/2.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/3.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/4.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/5.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/6.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col-lg-12">
                                    <div className="client-thumb">
                                        <img src="images/about/3.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>


                {/* <div className="container">
                    <div className="row clients-logo">


                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/1.png" alt="" className="img-fluid" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/2.png" alt="" className="img-fluid" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/3.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/4.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/5.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/6.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/3.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/4.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/5.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="client-thumb">
                                <img src="images/about/6.png" alt="" className="img-fluid" />
                            </div>
                        </div>

                    </div>


                </div> */}
            </div>





        </div >
    );
}

export default Home;
