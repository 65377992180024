import React, { useState, useEffect } from "react"
import "./css/style1.css"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function SignupPatients() {
    const [selectedDate, setSelectedDate] = useState(null);
    const [isValidAge, setIsValidAge] = useState(true);

    const handleDateChange = (date) => {
        setSelectedDate(date);

        // Validate the age
        const today = new Date();
        const minAgeDate = new Date();
        minAgeDate.setFullYear(today.getFullYear() - 18);

        setIsValidAge(date <= minAgeDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValidAge) {
            // Perform registration or further actions
            console.log('Registration successful!');
        } else {
            console.log('Age must be 18 or above to register!');
        }
    };


    const [Users, setUsers] = useState([]);

    const fetchServiceData = () => {
        fetch("http://localhost/doctor_admin/New/Api/ServiceFetch.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setUsers(data)
            })
    }

    useEffect(() => {
        fetchServiceData()
    }, [])
    return (
        <>
            <div className="signformD_section">


                <form className="modal-content" method="POST" action="http://localhost/doctor_admin/New/Api/signuppatients.php">
                    <div className="container">
                        <h2 className="heading_center">Welcome To Patient's Account Register Form</h2>
                        <img src="/images/patient.png" alt="patient" className="avatar" />

                        <hr />
                        <label htmlFor="text"><b>Patient Name</b></label>
                        <input type="text" placeholder="Enter Full Name" name="fullname" required />

                        <label htmlFor="email"><b>Email</b></label>
                        <input type="text" placeholder="Enter Email" name="email" required />

                        <label htmlFor="text"><b>Mobile Number</b></label>
                        <input type="text" placeholder="Enter Mobile Number" name="mobile" required />

                        <label htmlFor="text"><b>Gender</b></label><br />
                        <select name="gender" id="gender">
                            <option name="gender">Select</option>
                            <option name="gender">Male</option>
                            <option name="gender">Female</option>
                            <option name="gender">Other</option>

                        </select>

                        <label htmlFor="dob">
                            <b> Date of Birth:</b> </label><br />
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Date of Birth"
                            name="age"
                        />


                        {!isValidAge && (
                            <p style={{ color: '#fff' }}>Age must be 18 or above to register!</p>
                        )}

                        <label htmlFor="text"><b>Choose a Service</b></label>
                        <select name="services">
                            <option >Choose a Service:</option>
                           
                                {Users.map(User => (
                                    <option value={User.services} key={User.id}>{User.services}</option>
                                ))}</select>
                        {/* <select name="service" id="service">
                            <option >Select</option>
                            <option name="service">Service 1</option>
                            <option name="service">Service 2</option>
                            <option name="service">Service 3</option>
                            <option name="service">Service 4</option>
                        </select><br /> */}

                        <label htmlFor="text"><b>Address</b></label>
                        <input type="text" placeholder="Enter Your Address" name="address" required />

                        <label htmlFor="text"><b>City</b></label>
                        <input type="text" placeholder="Enter Your City" name="city" required />

                        <label htmlFor="text"><b>State</b></label>
                        <input type="text" placeholder="Enter Your State" name="state" required />

                        <label htmlFor="text"><b>Pincode</b></label>
                        <input type="text" placeholder="Enter Your Pincode" name="pincode" required />

                        <label htmlFor="psw"><b>Password</b></label>
                        <input type="password" placeholder="Enter Password" name="password" required />

                        <label htmlFor="psw-repeat"><b>Repeat Password</b></label>
                        <input type="password" placeholder="Repeat Password" name="rpassword" required />

                        <label>
                            <input type="checkbox" name="remember" /> Remember me
                        </label>


                        <div className="clearfix">

                            <button type="submit" name="submit" className="signupbtn buton1">Sign Up</button>
                        </div>
                    </div>
                </form>




            </div>
        </>

    );
}
export default SignupPatients;

