import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";



function Doctor() {
	const [Items, setItem] = useState([]);

	const fetchUserData = () => {
		fetch("http://localhost/doctor_admin/New/Api/specialistfetch.php")
			.then(response => {
				return response.json()
			})
			.then(data => {
				setItem(data)
			})
	}

	useEffect(() => {
		fetchUserData()
	}, [])

	// const [Users, setUser] = useState([]);

	// const fetchUserData1 = () => {
	// 	fetch("http://localhost/doctor_admin/New/Api/specialistcategory.php")
	// 		.then(response => {
	// 			return response.json()
	// 		})
	// 		.then(data => {
	// 			setUser(data)
	// 		})
	// }

	// useEffect(() => {
	// 	fetchUserData1()
	// }, [])


	return (
		<>
			

					<div className="page-title bg-1">
						<div className="overlay"></div>
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="block text-center">
										<span className="text-white">All Doctors</span>
										<h1 className="text-capitalize mb-5 text-lg">Specalized Doctors</h1>

										{/* <ul class="list-inline breadcumb-nav">
            <li class="list-inline-item"><Link to="index.html" class="text-white">Home</Link></li>
            <li class="list-inline-item"><span class="text-white">/</span></li>
            <li class="list-inline-item"><Link to="#" class="text-white-50">@@description</Link></li>
          </ul>  */}
									</div>
								</div>
							</div>
						</div>
					</div>

					<section className="section doctors">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-6 text-center">
									<div className="section-title">
										<h2>Doctors</h2>
										<div className="divider mx-auto my-4"></div>
										<p>We provide a wide range of creative services adipisicing elit. Autem maxime rem modi eaque, voluptate. Beatae officiis neque </p>
									</div>
								</div>
							</div>

							<div className="col-12 text-center  mb-5">
								<div className="btn-group btn-group-toggle btn" data-toggle="buttons">
									{/* <p>{Users.map(User => (
										<label className="btn" key={User.spl_id}>
											<input type="radio" className="shuffle-filter" value="spl_id" />{User.spl_category}
										</label>
									))}</p> */}
									<label className="btn active ">
										<input type="radio" name="shuffle-filter" value="all" />All Department
									</label>
									<label className="btn ">
										<input type="radio" name="shuffle-filter" value="spl_id?=1" />Cardiology
									</label>
									<label className="btn">
										<input type="radio" name="shuffle-filter" value="cat2" />Dental
									</label>
									<label className="btn">
										<input type="radio" name="shuffle-filter" value="cat3" />Neurology
									</label>
									<label className="btn">
										<input type="radio" name="shuffle-filter" value="cat4" />Medicine
									</label>
									<label className="btn">
										<input type="radio" name="shuffle-filter" value="cat5" />Pediatric
									</label>
									<label className="btn">
										<input type="radio" name="shuffle-filter" value="cat6" />Traumatology
									</label>
								</div>
							</div>
							{/* {Users.map(User => (
                                    <option value={User.spl_id} key={User.spl_id}>{User.spl_id},{User.doc_name}</option>
                                ))} */}
							<div className="row shuffle-wrapper portfolio-gallery">
								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;spl_id&quot;,&quot;cat2&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src={'images/team/1.jpg'} alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											{Items.map(Item => (

												<tr key={Item.doc_id}>
													<h4 className="mb-0">{Item.doc_name}</h4>
													<p className="mb-0">{Item.spl_category}</p>
												</tr>
											))}
											{/* <h4 className="mb-0">Thomas Henry</h4> 
									<p>Cardiology</p> */}
											{/* <p>{Users.map(User => (
										<tr key={User.spl_id}>
											<p className="mb-0">{User.spl_category}</p>
										</tr>
									))}</p> */}
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat2&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/2.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											{/* {Items.map(Item => (

												<tr key={Item.doc_id}>
													<h4 className="mb-0">{Item.doc_name}</h4>
													<p className="mb-0">{Item.spl_category}</p>
												</tr>
											))} */}
											<h4 className="mb-0">Harrision Samuel</h4>
									<p>Radiology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat3&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/3.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0">Alexandar James</h4>
											<p>Dental</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat3&quot;,&quot;cat4&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/4.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0">Edward john</h4>
											<p>Pediatry</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat5&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/1.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0">Thomas Henry</h4>
											<p>Neurology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat6&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/3.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0"><Link to="">Henry samuel</Link></h4>
											<p>Palmology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat4&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/1.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0"><Link to="">Thomas alexandar</Link></h4>
											<p>Cardiology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat5&quot;,&quot;cat6&quot;,&quot;cat1&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/3.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0"><Link to="">HarissonThomas </Link></h4>
											<p>Traumatology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item illustration" data-groups="[&quot;cat2&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/4.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0"><Link to="">Jonas Thomson</Link></h4>
											<p>Cardiology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item" data-groups="[&quot;cat5&quot;,&quot;cat6&quot;,&quot;cat1&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/3.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0"><Link to="">Henry Forth</Link></h4>
											<p>hematology</p>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item illustration" data-groups="[&quot;cat2&quot;]">
									<div className="position-relative doctor-inner-box">
										<div className="doctor-profile">
											<div className="doctor-img">
												<img src="images/team/4.jpg" alt="doctor" className="img-fluid w-100" />
											</div>
										</div>
										<div className="content mt-3">
											<h4 className="mb-0"><Link to="">Thomas Henry</Link></h4>
											<p>Dental</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>


				</>
				)
}
				export default Doctor;