// Card.js
const Card = ({filteredCard, loading}) => {
  
    if(loading)
      return <>Loading</> // use your loading state or component
  
    return (
      <div className="w-full h-[85%] px-5">
          <div className="w-full">details: </div>
          <div className="w-full h-full flex flex-wrap gap-1 justify-between items-start overflow-y-auto">
              {
                  filteredCard?.map((detail) => (
                      <div key={detail.id} className='w-[19%] h-fit my-3 rounded-xl overflow-hidden border border-gray-200'>
                          {/* <img
                              src={detail.thumbnail}
                              alt='detail'
                              className='w-full h-28 object-cover'
                          /> */}
                          <div className="mt-2 mb-2 px-3">
                              <div className="font-semibold">
                                  {(detail.doc_name.length > 25)? detail.title.substring(0,22) + '...': detail.title}
                              </div>
                              <div className="text-sm text-gray-600">
                                  {detail.category}
                              </div>
                          </div>
                      </div>
                  ))
              }
          </div>
      </div>
    )
  }
  
  export default Card