import React, { useState, useEffect } from "react";
// import { Container } from "react-bootstrap";
// const cors = require("cors");
// app.use(cors());
function BookedAppointment () {
  const [Items, setItem] = useState([]);

  const fetchUserData = () => {
    fetch("http://localhost/doctor_admin/New/Api/Fetch.php")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setItem(data)
      })
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  // useEffect(() => {
  //   fetch('http://localhost/doctor_admin/New/Api/Fetch.php')
  //   .then(res => res.json())
  //   .then(res => setItem(res))
  //   // .catch(error => console.error(error))
    

  // }, []);
// const getItem = async() => {
//   const reqData = await fetch("http://localhost/doctor_admin/New/Api/Fetch.php");
//   const resData = await reqData.json();
//   setItem(resData);
//   console.log(resData);
// }
// getItem();
//   },[]);
  return (
    <div>
      <h1>Fetch data into database</h1>
      <table>
        <thead>
          <tr>
            <th>appointment_id</th>
            <th>Name</th>
            <th>Email</th>
            <th>DOB</th>
            <th>Mobile</th>
            <th>Pincode</th>
            <th>State</th>
            <th>Role</th>
            <th>SubCategory</th>

          </tr>
        </thead>
        <tbody>
          { Items.map(Item => (

            <tr key={Item.appointment_id}>
              <td>{Item.appointment_id}</td>
              <td>{Item.name}</td>
              <td>{Item.email}</td>
              <td>{Item.dob}</td>
              <td>{Item.mobile_number}</td>
              <td>{Item.pincode}</td>
              <td>{Item.state}</td>
              <td>{Item.role}</td>
              <td>{Item.sub_category}</td>

            </tr>
          ))}
        
        </tbody>
      </table>
    </div>
  );
}

export default BookedAppointment;